import PropTypes from "prop-types"
import React from "react"

const PageTitle = ({ summary, title }) => (
    <div className={"mb-10 px-6 lg:mb-16 lg:px-12 xl:px-20 xl:w-2/3 mx-auto"}>
        <h1 className="text-4xl text-center tracking-tight leading-10 font-black text-gray-800 sm:text-5xl sm:leading-none md:text-6xl">
            {title}
        </h1>
        <p className="mt-3 sm:mt-5 font-sans lg:text-center prose prose-xl mx-auto">
            {summary}
        </p>
    </div>
)

PageTitle.propTypes = {
    summary: PropTypes.string,
    title: PropTypes.string,
}

export default PageTitle
